import React,
{
  useEffect,
  useState,
} from 'react';
import './App.css';

import {
  Provider,
} from 'react-redux';

import {
  ToastContainer,
} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import {
  persistStore,
} from 'redux-persist';

import {
  PersistGate,
} from 'redux-persist/integration/react';

import { version } from '../package.json';

import Routes from './navigation/routes/app.routes';

import store from './store';
import LoadingIniComponent from './components/Loading Ini';
import ApiWS from './services/api.service';
import { IToast } from './components/IToast/Toast';

import jwt_decode from 'jwt-decode';
import jwt from 'jwt-simple';


const App: React.FC = () => {
  const persistor = persistStore(store);
  const [screenLoading, setScreenLoading] = useState(false);
  const [telaDeLogin, setGoRouter] = useState(false);


  async function VerifyLicense() {
    setScreenLoading(true);

    const url = '/licenca/check';
    ApiWS('https://api.psuite.app.br')
    // ApiWS('https://api-h.psuite.app.br')
      .post(url)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('@intranet/validade', res.data.validade);
          localStorage.setItem('@intranet/config', JSON.stringify(res.data.ini));
          localStorage.setItem('@intranet/pasta', res.data.pasta || 'intranet');
          localStorage.setItem('@intranet/idEmp', jwt.encode(res.data.identificador, process.env.REACT_APP_SECRET_API) || 'intranet');
          // sessionStorage.setItem('logado', '1');
          setTimeout(() => {
            setScreenLoading(false);
            setGoRouter(true);
          },1000);
        }
        else {
          IToast({
            type: 'error',
            message: 'Erro ao verificar licença! Atualize a página, caso o problema persista contate o suporte.',
          });
          // setScreenLoading(false);
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Erro ao verificar licença! Atualize a página, caso o problema persista contate o suporte.',
        });
        // setScreenLoading(false);

        console.error('Licence Not Present', JSON.stringify(err));
      });
  };


  useEffect(() => {
    console.log(`Psuite Version: ${version}`);

    VerifyLicense();
    // if(sessionStorage.getItem('logado') !== '1') {
    //   VerifyLicense();
    // }
    // else {
    //   setGoRouter(true);
    // }
  }, []);



  return (

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>


        {screenLoading && (
          <>
            <LoadingIniComponent />
          </>
        )}


        {!screenLoading && telaDeLogin && (
          <Routes />
        )}

        <ToastContainer />
      </PersistGate>
    </Provider>

  );
};



export default App;
